<template>
  <b-card no-body class="shadow-none bg-transparente border-primary p-1">
    <div class="d-flex justify-content-start">
      <div class="mr-2">
        <b-avatar
          size="120"
          square
          variant="light-succes"
          src="@/views/Administrator/System/components/icons/ZK.png"
        />
      </div>

      <div class="w-100">
        <b-list-group flush>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-for="(i, index) in sd.data"
            :key="index"
          >
            <small>{{ i.title }}</small>
            <h6 class="d-flex flex-wrap justify-content-end align-items-center">
              <b-badge
                variant="light-success"
                style="margin: 5px 0 5px 5px"
                v-for="item in i.value"
                :key="item.id"
              >
                {{ item }}
              </b-badge>
            </h6>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-card>
</template>

<script>
  import {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BBadge,
  } from 'bootstrap-vue'
  export default {
    components: {
      BCard,
      BAvatar,
      BCardText,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      BBadge,
    },
    props: {
      sd: {
        type: Object,
        required: false,
      },
    },
    methods: {
      getImage(arg) {
        return this.data.filter(item => item.platform == arg)[0].image
      },
    },
    //     mounted(){
    //   console.log(this.sidata)
    // },
    data() {
      return {
        data: [
          {
            platform: 'linux',
            image: require('@/views/Administrator/System/components/icons/ZK.png'),
          },
        ],
      }
    },
  }
</script>

<style></style>
