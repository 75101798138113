<template>
  <div>
    <b-card no-body class="p-1 mb-0" style="min-height: 372px">
      <b-row>
        <b-col md="6">
          <h6 class="text-center">
            {{ $t('system.Memory') }}
          </h6>
          <apexRadialBarChart :data="system_monitor.system.volume[0]" />
        </b-col>
        <b-col md="6">
          <h6 class="text-center">
            {{ $t('system.RAM') }}
          </h6>
          <ramApexRadialChart :data="ramData" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { BCard, BRow, BCol } from 'bootstrap-vue'
  import apexRadialBarChart from './apexRadialBarCharts.vue'
  import RamApexRadialChart from './ramApexRadialChart.vue'
  import ramApexRadialChart from './ramApexRadialChart.vue'

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      apexRadialBarChart,
      RamApexRadialChart,
      ramApexRadialChart,
    },
    data() {
      return {
        // socketData: null,
        data1: [50, 23],
        data2: [60, 42],
      }
    },
    computed: {
      ...mapState('systemMonitor', ['system_monitor']),
    },
    props: {
      ramData: {
        type: Object,
        default: null,
      },
    },
    methods: {
      refresh(val) {
        this.data1 = val
      },
    },
  }
</script>
