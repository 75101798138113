<template>
  <b-card no-body>
    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart-b
        ref="chartjsComponentLineChartB"
        :height="400"
        :data="chartjsData.lineChart.data"
        :options="chartjsData.lineChart.options"
      />
      <!-- :plugins="plugins" -->
    </b-card-body>
  </b-card>
</template>

<script>
  import { BCard, BCardBody, BCardHeader, BCardTitle } from 'bootstrap-vue'

  import ChartjsComponentLineChartB from './ChartjsComponentLineChartB.vue'
  import chartjsData from './chartjsData'

  export default {
    components: {
      ChartjsComponentLineChartB,
      BCard,
      BCardBody,
      BCardHeader,
      BCardTitle,
    },
    data() {
      return {
        chartjsData,
        dataSet: null,
      }
    },

    props: {
      cpuData: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      refresh(val) {
        let dataSet = this.chartjsData.lineChart.data.datasets
        if (val) {
          for (let i = 0; i < val.length; i++) {
            if (val[i]) {
              if (val[i].percentage) {
                if (
                  val[i].percentage === 0 ||
                  val[i].percentage === null ||
                  val[i].percentage === undefined
                ) {
                  dataSet[0].data.push(0)
                } else {
                  dataSet[i].data.push(val[i].percentage)
                  dataSet[i].data.splice(0, 1)
                }
              } else if (val[i].percentage == null) {
                dataSet[i].data.push(0)
                dataSet[i].data.splice(0, 1)
              } else {
                dataSet[i].data.push(0)
                dataSet[i].data.splice(0, 1)
              }
            } else {
              dataSet[i].data.push(0)
              dataSet[i].data.splice(0, 1)
            }
            this.$refs.chartjsComponentLineChartB.$data._chart.update()
          }
          dataSet = this.dataSet
        } else {
          console.log('no data')
        }
      },
    },
  }
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
