<template>
  <b-card no-body class="shadow-none bg-transparente border-primary p-1">
    <div class="d-flex justify-content-start">
      <div class="mr-2">
        <b-avatar
          size="120"
          square
          variant="light-succes"
          src="@/views/Administrator/System/components/icons/ZK.png"
        />
      </div>
      <div class="w-100">
        <b-list-group flush>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Количество пользователей</small>
            <h6 class="font-weight-bold">
              {{ company_users.user_count }}
            </h6>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-for="(i, index) in sd.data"
            :key="index"
          >
            <small>{{ i.title }}</small>
            <h6
              class="font-weight-bold w-75 d-flex justify-content-end flex-wrap"
              v-if="typeof i.value === 'object'"
            >
              <b-badge
                variant="light-success"
                style="margin: 5px 0 5px 5px"
                v-for="item in i.value"
                :key="item.id"
              >
                {{ item[$i18n.locale] }}
              </b-badge>
            </h6>
            <h6 class="font-weight-bold" v-else>{{ i.value }}</h6>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BBadge,
  } from 'bootstrap-vue'
  export default {
    components: {
      BCard,
      BAvatar,
      BCardText,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      BBadge,
    },
    props: {
      sd: {
        type: Object,
        required: false,
      },
    },
    mounted() {
      // this.FETCH_COMPANY_USERS()
    },
    methods: {
      // ...mapActions('systemMonitor', ['FETCH_COMPANY_USERS']),

      getImage(arg) {
        return this.data.filter(item => item.products == arg)[0].image
      },
    },
    data() {
      return {
        data: [
          {
            products: 'linux',
            image: require('@/views/Administrator/System/components/icons/ZK.png'),
          },
        ],
      }
    },
    computed: {
      ...mapState('systemMonitor', ['company_users']),
    },
  }
</script>

<style>
  .font-weight-bold {
    font-weight: 600 !important;
  }
</style>
