<template>
  <div>
    <vue-apex-charts
      type="radialBar"
      height="307"
      :options="chartOptions"
      :series="percentageValue"
    />
  </div>
</template>

<script>
import { BCard, BCardTitle, BCardBody, BCardHeader } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    percentageValue() {
      return this.data?.percentage?.value
    },
  },

  data() {
    return {
      chartOptions: {
        colors: ['#B098FF', '#84D0FF'],
        plotOptions: {
          radialBar: {
            size: 185,
            hollow: {
              size: '50%',
            },
            track: {
              margin: 5,
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        stroke: {
          lineCap: 'round',
        },
        labels: [this.$t('Used'), this.$t('Available')],
      },
    }
  },
}
</script>
