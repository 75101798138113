<template>
  <b-card no-body class="shadow-none bg-transparente border-primary p-1">
    <div class="d-flex justify-content-start">
      <div class="w-100">
        <b-list-group flush>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Physical-cores</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.cpu_info
                  ? system_monitor.system.cpu_info.physical_cores.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Total cores</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.cpu_info
                  ? system_monitor.system.cpu_info.total_cores.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Max Frequency</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.cpu_info
                  ? system_monitor.system.cpu_info.max_frequency.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Min Frequency</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.cpu_info
                  ? system_monitor.system.cpu_info.min_frequency.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Current Frequency</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.cpu_info
                  ? system_monitor.system.cpu_info.current_frequency.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Total CPU Usage</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.cpu_info
                  ? system_monitor.system.cpu_info.total_cpu_usage.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-if="system_monitor && system_monitor[2]"
          >
            <small>Cores [{{ system_monitor[2].cores.length }}]</small>
            <b-badge
              v-for="(i, index) in system_monitor[2].cores"
              :key="index"
              class="mr-1"
              variant="light-success"
              >{{ i.percentage }}</b-badge
            >
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-card>
</template>

<script>
  import {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BBadge,
  } from 'bootstrap-vue'
  import { mapActions, mapState } from 'vuex'

  export default {
    components: {
      BCard,
      BAvatar,
      BCardText,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      BBadge,
    },
    methods: {},
    data() {
      return {}
    },
    computed: {
      ...mapState('systemMonitor', ['system_monitor']),
    },
  }
</script>

<style></style>
