export default {
  si: {
    platform: 'linux',
    data: [
      {
        title: 'Компания',
        value: 'Zamonaviy Kommunikatsiyalar',
      },
      {
        title: 'Сайт',
        value: 'zk.uz',
        link: 'https://zk.uz',
      },
      {
        title: 'Электронная почта',
        value: 'info@zk.uz',
      },
      {
        title: 'Контакты',
        value: '+998 94 164 66 33',
        link: 'tel:+998941646633',
      },
      {
        title: 'Лицензия',
        value: `© 2012-2022. ZK.UZ - Замонавий Коммуникациялар. Все права защищены.`,
      },
      {
        title: 'Адрес',
        value: 'Узбекистан, Ташкент, Чиланзарский р-н, с. Чиланзор, 1 кв./4',
      },
    ],
  },
  techno: {
    data: [
      {
        title: 'Systems',
        value: ['CRM', 'ERP', 'WMS', 'HRM', 'SRC'],
      },
      {
        title: 'Front-end',
        value: ['Java Script', 'React', 'Angular', 'Vue.js'],
      },
      {
        title: 'Back-end',
        value: [
          'Go',
          'Python',
          'Laravel',
          'PHP',
          'Django',
          'IoT',
          'FastAPI',
          'Flask',
        ],
      },
      {
        title: 'CI/CD',
        value: ['Jenkins', 'Gitlab', 'Kubernetes', 'Docker'],
      },
      {
        title: 'Database',
        value: ['Postgre SQL', 'MariaDB', 'MS SQL', 'MySQL'],
      },
    ],
  },
  products: {
    data: [
      {
        title: 'Модули системы',
        value: [
          {
            uz: "Yo'nalishlar ro'yxati",
            ru: 'Список рейсов',
          },
          {
            uz: 'Umumiy xarita',
            ru: 'Общая карта',
          },
          {
            uz: 'Qurilma',
            ru: 'Устройства',
          },
          {
            uz: 'Xabar jurnali',
            ru: 'Журнал оповещений',
          },
          {
            uz: "Ma'lumotlar bazasi",
            ru: 'Справочник',
          },
          {
            uz: 'Bojxona punktlari',
            ru: 'Таможенные пункты',
          },
          {
            uz: 'Tizim holati',
            ru: 'Состояние системы',
          },
          {
            uz: 'Foydalanuvchilar',
            ru: 'Пользователи',
          },
        ],
      },
      {
        title: 'Лицензия',
        value: 'ZK Digital Core',
      },
    ],
  },
}
