<template>
  <div>
    <!-- {{ system_monitor }} -->
    <!-- {{ system_monitor }} -->
    <b-row>
      <b-col md="4">
        <statistic-card-horizontal
          icon="CpuIcon"
          color="primary"
          :statistic="system_monitor.data[2].total_cpu_usage.value"
          :statistic-title="$t('CPU Usage')"
        />
      </b-col>

      <b-col md="4">
        <statistic-card-horizontal
          icon="ServerIcon"
          color="warning"
          :statistic="system_monitor.data[3].used.value"
          :statistic-title="$t('Memory Usage')"
        />
      </b-col>

      <b-col md="4">
        <statistic-card-horizontal
          icon="HardDriveIcon"
          color="danger"
          :statistic="system_monitor.data[5].volumes_used"
          :statistic-title="$t('Hard Drive Usage')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col class="col-md-5">
        <spaceUage ref="spaseUsage" :ramData="ramData" />
      </b-col>

      <b-col class="col-md-7">
        <ChartjsLineAreaChart ref="resourseUsage" :cpuData="cpuData" />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="7">
        <b-card>
          <bTabs />
        </b-card>
      </b-col>

      <b-col md="5">
        <b-card>
          <bTabsCompany />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { BCard, BRow, BCol } from 'bootstrap-vue'
import spaceUage from './components/spaceUsage.vue'
import resourceUsage from './components/resourceUsage.vue'
import bTabs from './components/bTabs'
import bTabsCompany from './components/bTabsCompany/index.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ChartjsLineAreaChart from './components/ChartjsLineAreaChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    spaceUage,
    resourceUsage,
    bTabs,
    bTabsCompany,
    StatisticCardHorizontal,
    ChartjsLineAreaChart,
  },
  data() {
    return {
      cpuData: null,
      ramData: null,
    }
  },
  async created() {
    await this.FETCH_SYSTEM_MONITOR()
  },
  computed: {
    ...mapState('systemMonitor', ['system_monitor']),
  },
  methods: {
    ...mapActions('systemMonitor', ['FETCH_SYSTEM_MONITOR']),
  },

  async mounted() {
    console.clear()
    await this.$_loaderStart()
  },

  async beforeRouteLeave(to, from, next) {
    await this.$_loaderStop()
    next()
  },

  beforeCreate() {
    // let socket = new WebSocket('ws://192.168.31.64:8002/')
    let socket = new WebSocket(process.env.VUE_APP_SOCKET)

    socket.onmessage = event => {
      if (event.data) {
        const data = JSON.parse(event.data)

        if (data.cpu) {
          this.cpuData = data.cpu.result
          this.$refs.resourseUsage.refresh(data.cpu.result)
        }

        if (data.ram) {
          this.ramData = data.ram
          this.$refs.spaseUsage.refresh(data.ram)
        }
      }
    }
  },
}
</script>
